.contactCardsContainer {
  display: flex;
  flex-direction: row;
  /* margin: auto; */
  width: 100%;
  height: 10rem;
}

.eachContactCard {
  width: 50%;
  height: 100rem;
}
